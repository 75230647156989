export default {
    mutateCommonProperties ({commit}, payload) {
        commit('mutateCommonProperties', payload)
    },
    stateReload ({commit}, payload) {
        commit('stateReload', payload)
    },
    setAuthUser ({commit}, payload) {
        commit('setAuthUser', payload)
    },
    setAccountDetails ({commit}, payload) {
        commit('setAccountDetails', payload)
    },
    setNotice ({commit}, payload) {
        commit('setNotice', payload)
    },
    setNavbarIssue ({commit}, payload) {
        commit('setNavbarIssue', payload)
    },
    updateNavbarIssue ({commit}, payload) {
        commit('updateNavbarIssue', payload)
    },
    setList ({commit}, payload) {
        commit('setList', payload)
    },
    setLang ({commit}, payload) {
        commit('setLang', payload)
    },
}