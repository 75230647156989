<template>
    <div class="backend-wrapper">
        <div class="sidebar-overlay" :class="{ 'sidebar-open': isOpen }" @click="toggleSidebar"></div>
        <nav id="sidebar" class="sidebar" :class="{ 'sidebar-open': isOpen }" @click="toggleSidebar">
            <div class="sidebar-content">
                <div class="sidebar-brand">
                    <a href="/merchant/dashboard">
                        <div class="brand-logo">
                            <img class="img-fluid" src="../assets/imgs/logo.png" alt="Bahok">
                        </div>
                    </a>
                    <p>{{ authUser.business }}</p>
                    <p>ID: BHKM{{ authUser.id }}</p>
                </div>
                <Sidebar />
            </div>
        </nav>

        <div class="main">
            <nav class="dashboard-navbar">
                <div class="header-navbar">
                    <span class="sidebar-toggle-btn d-block d-sm-none" @click="toggleSidebar">
                        <b-icon icon="list" variant="light"></b-icon>
                    </span>
                    <div class="news-ticker bg-light rounded p-1">
                        <vue-marquee-slider
                            id="marquee-slider"
                            :speed="15000"
                            :width="550"
                            >
                            <span class="font-weight-bold text-success" v-for="(item, index) in noticeList" :key="index">{{ item.notice }}</span>
                        </vue-marquee-slider>
                    </div>
                    <div class="header-profile">
                        <router-link to="profile" class="avatar">
                            <img title="Profile Edit" v-if="authUser.image" class="img-fluid" src="../assets/imgs/user.avif" style="width:30px;height:30px;border:2px solid#CA193A">
                            <img title="Profile Edit" v-else class="img-fluid" src="../assets/imgs/avatar.png" style="width:30px;height:30px;border:2px solid#CA193A">
                        </router-link>
                    </div>
                </div>               
            </nav>

            <main class="content">
                <router-view />
            </main>
        </div>
    </div>
</template>
<script>
import Sidebar from '@/components/Sidebar.vue';
import DropdownMixin from '@/mixins/dropdown'
export default {
    name: 'DashboardLayout',
    mixins: [DropdownMixin],
    components: {
        Sidebar
    },
    data() {
        return {
            isOpen: true,
            notices: [
                {text: 'dummy text'},
                {text: 'dummy text 2'}
            ]
        };
    },
    computed: {    
        authUser: function () {
            return this.$store.state.authUser
        },
        noticeList: function () {
            return this.$store.state.notices
        }
    },
    methods: {
        toggleSidebar() {
            this.isOpen = !this.isOpen;
        },
    },
}
</script>