<template>
    <div>
        <section class="footer-cta">
            <b-container>
                <b-row>
                    <b-col sm="5">
                        <div class="d-flex">
                            <img class="img-fluid pr-2 pb-2" src="../assets/imgs/logo.png" alt="Bahok">
                            <div>
                                <h3 class="mb-0">Location</h3>
                                <p>Mirpur-10, Dhaka-1216</p>
                            </div>
                        </div>
                        <p>Bahok Courier Limited established in 2015 to provide super fast delivery inside Dhaka city sub area of Dhaka and Chittagong City corporations.</p>
                    </b-col>
                    <b-col sm="3">
                        <h3>Join us on</h3>
                        <div class="social-icons-btn">
                            <a class="icons px-2 py-1 mr-1 bg-light rounded"  href="#">
                                <b-icon icon="twitter"></b-icon>
                            </a>
                            <a class="icons px-2 py-1 mr-1 bg-light rounded"  href="#">
                                <b-icon icon="facebook"></b-icon>
                            </a>
                            <a class="icons px-2 py-1 mr-1 bg-light rounded"  href="#">
                                <b-icon icon="instagram"></b-icon>
                            </a>
                            <a class="icons px-2 py-1 mr-1 bg-light rounded"  href="#">
                                <b-icon icon="linkedin"></b-icon>
                            </a>
                        </div>
                    </b-col>
                    <b-col sm="4">
                        <h3>Newsletter</h3>
                        <b-nav-form>
                            <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
                            <b-button varient="secondary" size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
                        </b-nav-form>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        <section class="footer-nav">
            <b-container>
                <b-row>
                    <b-col sm="5">
                        <h3>About Bahok</h3>
                        <ul>
                            <li><router-link to="/t">Our Mission</router-link></li>
                            <li><router-link to="/t">Our Vision</router-link></li>
                        </ul>
                    </b-col>
                    <b-col sm="5">
                        <h3>Services</h3>
                        <ul>
                            <li><router-link to="/t">Our Packaging</router-link></li>
                            <li><router-link to="/t">Become Rider</router-link></li>
                            <li><router-link to="/t">Become Agent</router-link></li>
                            <li><router-link to="/t">Warehouse Support</router-link></li>
                            <li><router-link to="/t">Fraud Alert Support</router-link></li>
                            <li><router-link to="/t">Coverage Area</router-link></li>
                        </ul>
                    </b-col>
                    <b-col sm="2">
                        <h3>Help</h3>
                        <ul>
                            <li><router-link to="/t">FAQ</router-link></li>
                            <li><router-link to="/t">About US</router-link></li>
                            <li><router-link to="/t">Privacy Policy</router-link></li>
                            <li><router-link to="/t">Terms & Conditions</router-link></li>
                        </ul>
                    </b-col>
                </b-row>
            </b-container>
        </section>
        <section class="bg-secondary text-light text-center py-2">
            <p>© 2023 - 2030 All Rights Reserved to Bahok Courier</p>
        </section>
    </div>
</template>
<script>

export default {
    components: {

    }
}
</script>