import Vue from 'vue'
import axios from 'axios'
import router from './router/index.js'
import './plugins'
import i18n from './i18n'
import store from './store'
import './assets/styles/main.scss'
import App from './App.vue'

Vue.config.productionTip = false

Vue.prototype.$baseUrl = 'https://api.bahokbd.com/api/';
Vue.prototype.$image_path = 'https://api.bahokbd.com/';
Vue.config.productionTip = false
Vue.prototype.$axios = axios;

//if unauthenticated redirect to login page
axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401) {
    this.$router.push('/')
  }
  return error;
});


var vm = new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

window.vm = vm
