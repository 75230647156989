export default {
    mutateCommonProperties (state, payload) {
        state.commonObj = Object.assign({}, state.commonObj, payload)
    },
    stateReload (state, payload) {
        state.stateReload = payload
    },
    setAuthUser (state, user) {
        state.authUser = user
    },
    setNotice (state, notices) {
        state.notices = notices
    },
    setAccountDetails (state, accountDetail) {
        state.accountDetail = accountDetail
    },
    setNavbarIssue (state, payload) {
        state.issueList = payload
    },
    updateNavbarIssue (state, payload) {
        state.issueList = state.issueList.filter(el => el.order_issue_id != payload)
    },
    setList (state, payload) {
        state.list = payload
    },
    setLang (state, payload) {
        state.language = payload
    },
}