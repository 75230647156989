<template>
    <b-navbar toggleable="lg" type="dark" variant="secondary">
        <b-container>
          <b-navbar-brand href="/">
              <img class="img-fluid" src="../assets/imgs/logo.png" alt="Bahok">
          </b-navbar-brand>
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
              <b-nav-item to="#" @click="redirectToUrl">Home</b-nav-item>
              <b-nav-item to="#" @click="redirectToUrl">About</b-nav-item>
              <b-nav-item to="#" @click="redirectToUrl">FAQ</b-nav-item>
              <b-nav-item to="#" @click="redirectToUrl">Covarage Area</b-nav-item>
              <!-- <b-nav-item-dropdown text="Services" right>
                <b-dropdown-item to="#">EN</b-dropdown-item>
                <b-dropdown-item to="#">ES</b-dropdown-item>
              </b-nav-item-dropdown> -->
              <b-nav-item to="#" @click="redirectToUrl">Rate</b-nav-item>
              <b-nav-item to="#" @click="redirectToUrl">Contact Us</b-nav-item>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
              <b-nav-form>
                <b-button size="sm" class="my-2 my-sm-0 mr-3" type="submit" variant="light">Login</b-button>
                <b-button size="sm" class="my-2 my-sm-0" type="submit" variant="light">Become a Marchant</b-button>
              </b-nav-form>
            </b-navbar-nav>
          </b-collapse>
        </b-container>
    </b-navbar>
</template>
<script>
export default {
   methods: {
    redirectToUrl() {
      window.location.href = 'https://bahokcourier.com/'
    }
   }
}
</script>
