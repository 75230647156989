import config from '../config'
export default {
    created () {
      config.getData('/common')
      .then(response => {
        if (response.status_code === 200) {
          this.$store.commit('mutateCommonProperties', {
            divisionList: response.divisionList,
            districtList: response.districtList,
            thanaList: response.thanaList,
            // merchantList: response.merchantList,
            coverageAreaList: response.coverageAreaList,
            areaList: response.areaList,
            // merchantAreaList: response.merchantAreaList,
            storeList: response.storeList,
            bankList: response.bankList,
            hubList: response.hubList,
            riderList: response.riderList,
            reasonList: response.reasonList,
            weightChargeList: response.weightChargeList,
            issueList: response.issueList,
          })
        }
      })
    }
}