<template>
    <div>
        <header>
            <PortalNavbar />
        </header>
        <main>
            <router-view />
        </main>
        <footer>
            <PortalFooter />
        </footer>
    </div>
</template>
<script>
import PortalFooter from '@/components/PortalFooter.vue';
import PortalNavbar from '@/components/PortalNavbar.vue';
export default {
    components: {
    PortalNavbar,
    PortalFooter
}
}
</script>